.sidenavigationbarlogout {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  margin-top: calc(25 / 801 * 100vh);
}
.sidenavigationbarlogout > .MuiSvgIcon-root {
  color: #319a96;
  width: calc(40 / 1440 * 100vw);
  height: calc(40 / 900 * 100vh);
  margin-top: calc(10 / 801 * 100vh);
  margin-bottom: calc(10 / 801 * 100vh);
}
.sidenavigationbarlogout > h3 {
  font-size: calc(18 / 1440 * 100vw);
  font-weight: 500;
  color: #319a96;
  margin-top: calc(15 / 801 * 100vh);
  margin-bottom: calc(5 / 801 * 100vh);
}
.sidenavigationbarlogout:hover {
  background: rgba(171, 171, 171, 0.3);
}
