.nav{
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin-top: 80px;
  padding: 5px;
  width: 99%;
  text-decoration: none;
  padding-bottom: 0;
  margin-bottom: 0;
}
.li-n{
    text-decoration: none;
    border-radius: 2px;
    padding: 6px;
    flex: 1;
    align-items: center;
    text-align: center;
}
.li-n:hover{
    background-color: rgba(243, 217, 217, 1);
    /* cursor: grab; */
}

.li-a{
    text-decoration: none;
    border-radius: 2px;
    padding: 6px;
    flex: 1;
    align-items: center;
    text-align: center;
    background-color: rgba(243, 217, 217, 1);
}
#hr{
    padding: 0;
    margin: 0;
}