.mentorsstartupsyoufollowInfocard {
  width: 320px;
  display: flex;
  margin: 20px;
  padding: 10px;
  background-color: rgba(100, 227, 176, 0.05);
}
.mentorsstartupsyoufollowInfocard_Content {
  padding: 10px;
}
.mentorsstartupsyoufollowInfocard_Avatar {
  width: 120px;
  height: 120px;
}
.mentorsstartupsyoufollowInfocard > img {
  width: 120px;
  height: 120px;
}
.mentorsstartupsyoufollowInfocard_Head {
  display: flex;
}

.mentorsstartupsyoufollowInfocard_Head > p {
  flex: 1;
}
#Infocard_GreenIndie {
  color: #595959;
}
.mentorsstartupsyoufollowInfocard_Span {
  color: #319a96;
}
.mentorsstartupsyoufollowInfocard_MailIcon {
  color: #319a96;
}
