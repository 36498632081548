.nav {
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin-top: 80px;
  padding: 5px;
  width: 99%;
  text-decoration: none;
  padding-bottom: 0;
  margin-bottom: 0;
}
.aboutstartupsummaryli-n {
  text-decoration: none;
  border-radius: 2px;
  padding: 6px;
  flex: 1;
  align-items: center;
  text-align: center;
}
.aboutstartupsummaryli-n:hover {
  background-color: rgba(243, 217, 217, 1);
  cursor: grab;
}

.aboutstartupsummaryli-a {
  text-decoration: none;
  border-radius: 2px;
  padding: 6px;
  flex: 1;
  align-items: center;
  text-align: center;
  background-color: rgba(243, 217, 217, 1);
}
#hr {
  padding: 0;
  margin: 0; 
}

.aboutstartupsummaryContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
}
.aboutstartupsummaryCard {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  border: 2px solid black;
  overflow-wrap: break-word;
  text-align: center;
  flex: 1;
  margin: 20px;
  padding: 10px;
  height: 220px;
  border-radius: 25px;
}
.aboutstartupsummaryCard:hover {
  background-color: rgba(243, 217, 217, 1);
  /* cursor: grab; */
}
.aboutstartupsummaryimg {
  padding-top: 20px;
}
.aboutstartupsummaryCard-Topic {
  font-size: 18px;
  font-weight: bold;
  padding-bottom: 20px;
} 
