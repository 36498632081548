.homebody {
  display: flex;
  align-items: center;
  background-image: url("https://i.pinimg.com/564x/45/59/fa/4559fadeaacea0e4fed0189022d31814.jpg");
  /* background-size: 100% 100%; */
  background-repeat: no-repeat;
  background-attachment: fixed;
  height: 100%;
  width:auto;
  position: fixed;
}
.homebody1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
}
.sidenavigationbarapp {
  display: flex;
  top: 0px;
}
.sidebardiv {
  top: calc(471 / 2638 * 100vh);
  position: absolute;
  display: fixed;
  height: 100vh;
  margin-right: 20px;
  z-index: 100;
}
.sidenavigationbarside {
  flex: calc(187 / 801 * 900 / 1440);
  height: 100%;
  display: fixed;
  top: 0px;
  background-color: #424242;
  display: flex;
  flex-direction: column;
}
.homebody-tag1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* width: calc(1210 / 1440 * 80vw); */
  background-color: black;
  opacity: 0.8;
  padding: 20px;
  margin: 20px;
  border-radius: 40px;
}
.homebody-tag1 > h2 {
  color: yellow;
  margin-top: 0px;
  margin-bottom: 0px;
}
.homebody-tag1 > p {
  color: white;
}
.homebody__button1 > button {
  background-color: rgb(49, 154, 150);
  color: white;
  font-weight: bold;
  border-radius: 10px;
  margin-top: calc(25 / 900 * 100vh);
  font-size: 18px;
  padding: 2px 20px;
  cursor: pointer;
}
.homebody-tag2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(1210 / 1440 * 80vw);
  background-color: black;
  opacity: 0.8;
  padding: 20px;
  margin: 20px;
  border-radius: 40px;
}
.homebody-tag2 > h2 {
  color: yellow;
  margin-top: 0px;
  margin-bottom: 0px;
}
.homebody-tag2 > p {
  color: white;
}
.fundraiser_bottom {
  display: flex;
  margin-top: calc(25 / 900 * 80vh);
}
.fundraiser_bar {
  width: calc(920 / 1440 * 80vw);
  height: calc(30 / 900 * 100vh);
  border-radius: calc(10 / 1440 * 100vw);
  background-color: #ffffff;
  margin-top: calc(5 / 900 * 100vh);
  margin-right: calc(4 / 1440 * 100vw);
  display: flex;
}
.fundraiser_bar_content {
  background-color: rgba(255, 255, 1, 1);
  border-radius: calc(10 / 1440 * 100vw);
  flex: 0.78;
}
.fundraiser_bar_percent {
  color: rgba(49, 154, 150, 1);
}
.homebody__button2 > button {
  background-color: rgb(49, 154, 150);
  width: calc(180 / 1440 * 80vw);
  height: calc(43 / 900 * 100vh);
  color: white;
  font-weight: bold;
  border-radius: 10px;
  font-size: 18px;
  padding: 2px 20px;
  cursor: pointer;
}
.homebody-tag3 {
  background-color: black;
  opacity: 0.8;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 60px;
  width: 50%;
  padding: 20px;
  margin: 40px;
}
.homebodyimg {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
}
.homebodyimg-content {
  padding: 10px;
}
.homebodyimg1 {
  color: yellow;
  font-size: x-large;
}
.homebodyimg2 {
  color: white;
  font-size: x-large;
}
.homebody-tag5 {
  background-color: black;
  opacity: 0.8;
  width: 80%;
  border-radius: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  margin: 40px;
  padding-bottom: 60px;
}
.homebody-tag5-card {
  display: flex;
  align-items: center;
}
.homebody-tag5-card-img {
  width: 30%;
}
.homebody-tag5-card-content {
  flex: 1;
  padding: 10px 40px;
  margin: 10px 60px;
}
.homebody-tag5-card-content > h1 {
  color: yellow;
}
.homebody-tag5-card-content > p {
  color: white;
  padding: 0px 10px;
  margin: 0px 10px;
  font-size: large;
}
.otherlinks {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.justsomebutton {
  background-color: #ff851b;
  margin-bottom: 2vh;
  width: 20vw;
  height: 5vh;
  cursor: pointer;
  font-size: 1.5em;
}

@media screen and (max-width:1151px) {
  .homebody__button2 > button {
    font-size: 15px;
    padding: 0px 5px;
  }
  
}
@media screen and (max-width: 768px) {
  .homebody__button2 > button {
    font-size: 16px;
    padding: 2px 15px;
  }
  }


@media screen and (max-width:490px) {
  .homebody-tag3 {
       width: 70%;
  
  }
  }
  @media screen and (max-width:340px) {
    .homebody-tag3 {
         width: 88%;
    
    }
    }
