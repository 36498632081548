@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");

.mentorregistrationformapp_header {
  height: calc(471 / 2638 * 100vh);
  width: 100vw;
  background-color: #000000;
  display: flex;
}

.mentorregistrationformapp_footer {
  height: calc(289 / 2638 * 100vh);
  width: 100vw;
  background-color: #000000;
  position: absolute;
  bottom: 0;
}

.mentorregistrationformcontainer {
  width: 1104px;
  height: 660px;
  position: absolute;
  left: 50%;
  top: 53%;
  transform: translate(-50%, -50%);
  background: rgba(49, 154, 150, 1);
  /* border: 4.63866px solid #000000; */
  /* box-sizing: border-box; */
  border-radius: 46.3866px;
  border: 3px solid black;
}

.mentorregistrationformheading {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: bolder;
  font-size: 55.6639px;
  line-height: 68px;

  color: #000000;
  position: absolute;
  top: 53px;
  left: 59px;
  z-index: 10;
}

.mentorregistrationformcontainer > img {
  width: 454px;
  position: absolute;
  left: 70px;
  top: 305px;
}

.mentorregistrationformsmall-circle {
  background-color: #fff;
  width: 22.3px;
  height: 22.3px;
  border-radius: 11.15px;
  position: absolute;
  left: 57px;
  top: 233px;
}

.mentorregistrationformbig-circle {
  background-color: #fff;
  width: 31.5px;
  height: 31.5px;
  border-radius: 15.75px;
  position: absolute;
  left: 17px;
  top: 279px;
}

.mentorregistrationformhorizontal-line-1 {
  position: absolute;
  height: 0px;
  width: 94.62857055664062px;
  left: 110px;
  top: 44.531005859375px;
  border-radius: nullpx;
  border: 4.1px solid rgba(255, 236, 236, 1);
  box-shadow: 0px 3.7109243869781494px 3.7109243869781494px 0px
    rgba(0, 0, 0, 0.25);
}

.mentorregistrationformhorizontal-line-2 {
  position: absolute;
  height: 0px;
  width: 92.77310943603516px;
  left: 110px;
  top: 212px;
  border-radius: nullpx;
  border: 4.1px solid rgba(255, 236, 236, 1);
  box-shadow: 0px 3.7109243869781494px 3.7109243869781494px 0px
    rgba(0, 0, 0, 0.25);

  background-color: rgba(255, 236, 236, 1);
}

.mentorregistrationformvertical-line-2 {
  position: absolute;
  height: 0px;
  width: 167px;
  left: 121px;
  top: 128px;
  border: 4.1px solid rgba(255, 236, 236, 1);
  box-shadow: 0px 3.7109243869781494px 3.7109243869781494px 0px
    rgba(0, 0, 0, 0.25);
  transform: rotate(-90deg);

  background-color: rgba(255, 236, 236, 1);
  z-index: 20;
}

.mentorregistrationformvertical-line-1 {
  position: absolute;
  height: 0px;
  width: 167px;
  left: 29px;
  top: 128px;
  border-radius: nullpx;
  border: 4.1px solid rgba(255, 236, 236, 1);
  box-shadow: 0px 3.71092px 3.71092px 0px rgba(0, 0, 0, 0.25);
  transform: rotate(-90deg);

  background-color: rgba(255, 236, 236, 1);
}
