.aboutstartupHeader-TopBar {
  display: flex;
  padding: 20px;
  background-color: black;
  align-items: center;
  height: 60px;
}

.aboutstartupHeader-TopBar-Items {
  padding: 10px;
}

#aboutstartupHeader-Name {
  color: white;
}

.aboutstartupHeader-Startup {
  display: flex;
  padding: 20px;
  justify-content: space-between;
  position: absolute;
  top: 140px;
  z-index: 100;
  width: 95%;
  align-self: center;
}

.aboutstartupHeader-Startup-Contact {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
}

.aboutstartupHeader-Startup-Contact-Content {
  display: flex;
  justify-content: space-between;
  height: 60%;
}
.aboutstartupheadernav {
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin-top: 80px;
  padding: 5px;
  width: 99%;
  text-decoration: none;
  padding-bottom: 0;
  margin-bottom: 0;
}
.aboutstartupheaderli-n {
  text-decoration: none;
  border-radius: 2px;
  padding: 6px;
  flex: 1;
  align-items: center;
  text-align: center;
}
.aboutstartupheaderli-n:hover {
  background-color: rgba(243, 217, 217, 1);
  /* cursor: grab; */
}

.aboutstartupheaderli-a {
  text-decoration: none;
  border-radius: 2px;
  padding: 6px;
  flex: 1;
  align-items: center;
  text-align: center;
  background-color: rgba(243, 217, 217, 1);
}
#aboutstartupheaderhr {
  padding: 0;
  margin: 0;
}
