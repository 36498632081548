.sidenavigationbarprofile {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  margin-top: calc(30 / 801 * 100vh);
}
.sidenavigationbarprofile > .MuiSvgIcon-root {
  color: #ffffff;
  width: calc(40 / 1440 * 100vw);
  height: calc(40 / 900 * 100vh);
  margin-top: calc(8 / 801 * 100vh);
}
.sidenavigationbarprofile > h3 {
  font-size: calc(10 / 1440 * 100vw);
  font-weight: 500;
  color: #ffffff;
  margin-bottom: calc(8 / 801 * 100vh);
}
.sidenavigationbarprofile:hover {
  background: rgba(171, 171, 171, 0.3);
}
