.blogbody {
  background-image: url("./background_images/bg2.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-attachment: fixed; 
}
.blog-heading{
    height: 100px;
    width: 100%;
    font-size: 160px;
    font-weight: bold;
    vertical-align: middle;
}
.blog-heading > h1{
    padding-top: 40px;
    padding-left: 80px;
    font-size: 90px;
}
.blogbody-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 60px 320px;
    border-radius: 2px;
    padding: 60px 0px;
    background-color: rgba(255, 255, 255, 0.2);
}
@media screen and (max-width: 1360px) {
    .blog-post{
        display:flex;
        flex-direction: column;
        justify-content: space-evenly;
        margin-bottom: 40px;
        background-color: rgba(0, 0, 0, 0.6);
        align-items: center;
    }
    .blog-post-img>img {
        height: 50%;
        width: 50%;
        padding: 0;
        margin: 0;
    }
    .blog-post-info {
        display: flex;
        flex: 1;
        flex-direction: column;
        width: 50%;
        margin: 30px 10px;
        margin-right: 20px;
    }
}
@media screen and (max-width: 800px) {
    .blogbody-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 60px 80px;
        border-radius: 2px;
        height: 100%;
        padding: 0 20px;
    }
    .blog-post {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        margin-bottom: 40px;
        background-color: rgba(0, 0, 0, 0.6);
        align-items: center;
    }

    .blog-post-img>img {
        height: 150;
        width: 180;
        padding: 0;
        margin: 0;
    }
    .blog-post-info {
        display: flex;
        flex: 1;
        flex-direction: column;
        width: 200px;
        margin: 30px 10px;
        margin-right: 20px;
    }
}
.blog-post{
    display:flex;
    justify-content: space-evenly;
    height: 260px;
    margin-bottom: 40px;
    background-color: rgba(0, 0, 0, 0.85);
}
.blog-post-img{
    align-items: center;
    vertical-align: middle;
    padding: 0;
}
.blog-post-img > img{
    height: 70%;
    width: 70%;
    padding: 0;
    margin: 0;
    vertical-align: middle;
    align-self: middle;
}
.blog-post-info{
    display:flex;
    flex: 1;
    flex-direction: column;
    width: 90%;
    margin: 30px 10px;
    margin-right: 20px;
}
.blog-post-info-date{
    color: white;
    font-size: 22px;
}
.blog-post-info-name{
    color: white;
    font-weight: bold;
}
.blog-post-info-info{
    color: white;
}