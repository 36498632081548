.notificationsmessage {
  margin-left: calc(6 / 1440 * 100vw);
  margin-top: calc(22 / 900 * 100vh);
  width: calc(868 / 1440 * 100vw);
  height: calc(603 / 900 * 100vh);
  background-color: #424242;
  border-radius: 10px;
}
.notificationsmessage__top {
  display: flex;
  justify-content: space-between;
}
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap");
.notificationsmessage__top > h2 {
  margin-top: calc(13 / 900 * 100vh);
  margin-left: calc(18 / 1440 * 100vw);
  height: calc(40 / 900 * 100vh);
  width: calc(60 / 1440 * 100vw);
  font-size: calc(14 / 900 * 100vh);
  font-weight: 500;
  color: #ffffff;
  font: "Montserrat", sans-serif;
}
.notificationsmessage__top > .MuiSvgIcon-root {
  margin-right: calc(13 / 1440 * 100vw);
  margin-top: calc(12 / 900 * 100vh);
  color: #ffffff;
  height: calc(20 / 900 * 100vh);
  width: calc(20 / 1440 * 100vw);
  cursor: pointer;
}
.notificationsmessage__body {
  display: flex;
}
.notificationsmessageimage > img {
  margin-top: calc(40 / 900 * 100vh);
  margin-right: calc(71.01 / 1440 * 100vw);
  width: calc(392.99 / 1440 * 100vw);
  height: calc(460 / 900 * 100vh);
}
.notificationsmessage__main {
  display: flex;
  flex-direction: column;
}
.notificationsmessage__main-head {
  color: #ffffff;
  margin-left: calc(80 / 1440 * 100vw);
  margin-top: calc(75 / 1440 * 100vh);
  margin-right: calc(253 / 1440 * 100vw);
  margin-bottom: calc(26 / 900 * 100vh);
  font: "Montserrat", sans-serif;
  font-size: calc(11 / 1440 * 100vw);
  font-weight: 400;
}
.notificationsmessage__main-body {
  width: fit-content;
  color: #ebebeb;
  border-top: calc(1 / 900 * 100vh) solid #ebebeb;
  padding-top: calc(11 / 900 * 100vh);
  margin-left: calc(80 / 1440 * 100vw);
  margin-right: calc(49 / 1440 * 100vw);
  font: "Montserrat", sans-serif;
  font-size: calc(14 / 1440 * 100vw);
  font-weight: 400;
}
