
.contactus_header {
  height: calc(471 / 2638 * 100vh);
  width: 100vw;
  background-color: #000000;
  display: flex;
}

.contactusApp {
  width: 100vw;
/*   height: 100vh; */
  box-sizing: border-box;

  background-image: url("./images/backgroundphone.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: 100% ;
  /* z-index: 5; */
  background-position: 0 -10px;
}

.contact-us-box {
  display: flex;
  flex-direction: row;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
}

.contact-us-heading {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;

  height: 24px;
  width: 242px;
  height: 58px;

  /* position: relative;
  left: 139px;
  top: 20px; */

  margin-top: 105px;
  margin-bottom: -30px;
  background: #fecb2f;
  border-radius: 20px 20px 0px 0px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.contact-us-box {
  box-sizing: border-box;
  width: 96%;
  height: auto;
  z-index: 1;
  /* position: relative; */
  /* left: 56px; */
  /* top: -13px; */
  background: #ffe597;
  opacity: 0.8;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 40px;
  padding: 44px;
}

.contactuscontent-left {
  flex: 1;
}

.contactusleft-heading {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;

  color: #000000;
}

.contactusleft-body {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;

  color: #00848c;
  margin-bottom: 40px;
  padding-right: 30px;
}

.contactussocial-media {
  display: flex;
  flex-direction: row;
}

.contactussocial-media > a > img {
  padding: 0 4px 0 4px;
}

.contactusright-box {
  flex: 1;
  width: 50%;
  z-index: 100;

  box-sizing: border-box;
  width: 457px;
  left: 508px;
  top: 250px;

  background: #fecb2f;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 40px;
  padding: 40px 26px;
}

.contactusget-in-touch {
  z-index: 10;
  /* visibility: ; */
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
}

.contactussocialtree {
height: 250px;
    object-fit: cover;
  margin-left: auto;
  margin-right: auto;
}

.contactus_footer {
  margin-top: 0px;
  height: 20px;
  width: 100vw;
  background-color: #000000;
  display: flex;
}

@media screen and (max-width: 500px) {
  
  .contactusApp{
    background: none;
    margin-top: -40px;

    /* padding: 0 10px; */
  }

  .contactussocial-media {
    position: absolute;
    right: 13px;
    top: 490px;
    display: flex;
    flex-direction: column;
    /* background-color: #00848c; */
    align-items: center;
    /* justify-items: center; */

  }
  .contactussocial-media > a > img {
    padding: 10px 4px 0 4px;
  }

  .contact-us-box {
    margin-bottom: 10px;
  }
  
}