.chatsidebar {
  display: flex;
  width: calc(439 / 1440 * 100vw);
  flex-direction: column;
  height: calc(1878 / 2638 * 100vh);
}
.chatsidebar__search {
  /* display: flex; */
  background-color: rgba(49, 154, 150, 0.19);
  width: calc(439 / 1440 * 100vw);
}
.chatsidebar__searchContainer {
  display: flex;
  align-items: center;
  width: calc(318 / 1440 * 100vw);
  height: calc(40.15 / 903 * 100vh);
  margin-left: calc(61 / 1440 * 100vw);
  margin-top: calc(28.29 / 903 * 100vh);
  margin-right: calc(60 / 1440 * 100vw);
  background-color: #319a96;
  border-radius: 8px;
}
.chatsidebar__searchContainer > .MuiSvgIcon-root {
  color: #ffffff;
  height: calc(30 / 903 * 100vh);
  width: calc(27.38 / 1440 * 100vw);
  padding-left: calc(11 / 1440 * 100vw);
  /* padding-right: calc(277 / 1440 * 100vw); */
  padding-top: calc(7.3 / 903 * 100vh);
  padding-bottom: calc(5.48 / 903 * 100vh);
}
.chatsidebar__searchContainer > input {
  background-color: #319a96;
  flex: 0.98;
}

.chatsidebar__searchContainer > ::placeholder {
  color: #ffffff;
  font-family: montserrat;
  font-style: normal;
  font-weight: normal;
}

.chatsidebar__chats {
  background-color: rgba(49, 154, 150, 0.19);
  flex: 1;
  overflow: scroll;
  border: 1px solid #c4c4c4;
}
::-webkit-scrollbar {
  width: 0;
  background: transparent;
}
