@import url("https://fonts.googleapis.com/css2?family=Heebo:wght@100;200;300;400;500;600;700;800;900&family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");

/* font-family: 'Heebo', sans-serif;
font-family: 'Montserrat', sans-serif; */

* {
  margin: 0;
  padding: 0;
}

.startupinsight_header {
  height: calc(471 / 2638 * 100vh);
  width: 100vw;
  background-color: #000000;
  display: flex;
}

#startupinsightblackborder {
  position: relative;
  top: 50px;
  width: 100%;
}

.startupinsighttop-heading {
  position: absolute;
  width: 884px;
  height: 277px;
  left: 8%;
  top: 210px;

  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 120px;
  line-height: 146px;

  color: #000000;
}

.startupinsightbottom-heading {
  position: absolute;
  width: 884px;
  height: 277px;
  left: 8%;
  top: 346px;

  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 120px;
  line-height: 146px;

  color: #000000;
}

.startupinsightright-box {
  position: absolute;
  width: 499px;
  height: 517px;
  right: 10%;
  top: 249px;

  background: #dedbdb;
  border-radius: 5px;

  box-sizing: border-box;
  padding: 30px 40px 0px 40px;
  text-align: center;

  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
}

.startupinsightright-box-register > button {
  border-radius: 5px;
  padding: 7px 10px;
  margin-top: 20px;
  background-color: #000000;
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  text-align: center;

  color: #d3d3d3;
}

.startupinsightbottom {
  padding: 70px 9%;
  margin-top: 50px;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
}

.startupinsightbox1,
.startupinsightbox2,
.startupinsightbox3,
.startupinsightbox4,
.startupinsightbox5,
.startupinsightbox6,
.startupinsightbox7,
.startupinsightbox8,
.startupinsightbox9 {
  width: 33.33%;
  height: 280px;
  padding: 30px 10px 20px 30px;
  box-sizing: border-box;
  color: white;
}

.startupinsightbox1,
.startupinsightbox5,
.startupinsightbox9 {
  background: rgba(254, 203, 47, 1);
}

.startupinsightbox2,
.startupinsightbox6,
.startupinsightbox7 {
  background: black;
}

.startupinsightbox3,
.startupinsightbox4,
.startupinsightbox8 {
  background: rgba(49, 154, 150, 1);
}

.startupinsightbox-heading {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 35px;
  line-height: 43px;

  color: #ffffff;
}

.startupinsightbox-symbol {
  position: relative;
  left: 30%;
  top: -18%;
}

.startupinsightbox-text {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;

  color: #ffffff;

  margin-top: 10px;
}

.startupinsight_footer {
  /* margin-top: 30px; */
  height: calc(289 / 2638 * 100vh);
  width: 100vw;
  background-color: #000000;
  display: flex;
}

@media only screen and (max-width: 1150px) {
  .startupinsighttop-heading {
    font-size: 90px;
  }

  .startupinsightbottom-heading {
    font-size: 90px;
  }

  .startupinsightright-box {
    position: absolute;
    width: 40%;
    height: 470px;
    top: 210px;

    padding: 30px 30px 30px 30px;
    font-size: 20px;
    line-height: 24px;
  }
  .startupinsightbottom {
    display: flex;
    justify-content: center;
    padding: 100px 20px;
  }

  .startupinsightbox1,
  .startupinsightbox2,
  .startupinsightbox3,
  .startupinsightbox4,
  .startupinsightbox5,
  .startupinsightbox6,
  .startupinsightbox7,
  .startupinsightbox8,
  .startupinsightbox9 {
    width: 433px;
    height: 280px;
    padding: 30px 10px 20px 30px;
    border: 1px solid white;
  }
}

@media only screen and (max-width: 1000px) {
  .startupinsighttop-heading {
    position: absolute;
    width: 884px;
    height: 277px;
    left: 5%;
    top: 210px;

    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 70px;
    line-height: 146px;

    color: #000000;
  }

  .startupinsightbottom-heading {
    position: absolute;
    width: 884px;
    height: 277px;
    left: 5%;
    top: 306px;

    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 70px;
    line-height: 146px;

    color: #000000;
  }

  .startupinsightright-box {
    position: absolute;
    width: 40%;
    height: fit-content;
    top: 210px;

    padding: 30px 20px 20px 20px;
    font-size: 20px;
    line-height: 22px;
  }

  .startupinsightright-box-register > button {
    font-size: 24px;
    line-height: 28px;
  }
}

@media only screen and (max-width: 850px) {
  .startupinsighttop-heading {
    position: absolute;
    width: 884px;
    height: 277px;
    left: 8%;
    top: 190px;

    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 80px;
    line-height: 146px;

    color: #000000;
  }

  .startupinsightbottom-heading {
    position: absolute;
    width: 884px;
    height: 277px;
    left: 8%;
    top: 286px;

    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 80px;
    line-height: 146px;

    color: #000000;
  }

  .startupinsightright-box {
    /* position: absolute; */
    position: relative;
    /* top: 50%; */
    /* left: 50%; */
    margin-top: -40px;
    width: 450px;
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 200px;
    align-self: center;
    border-bottom-right-radius: 10px;
    /* width: 40%; */
    /* width: 50%; */
    height: fit-content;
    top: 170px;
    padding: 30px;
    font-size: 20px;
    line-height: 28px;

    box-shadow: 20px 20px 10px grey;
  }

  .startupinsightright-box-register > button {
    font-size: 24px;
    line-height: 28px;
  }
}
@media screen and (max-width: 600px) {
  .startupinsightright-box {
    position: relative;
    left: 10px;
  }
}
@media screen and (max-width: 520px) {
  .startupinsightright-box {
    width: 350px;
    position: relative;
    top: -200px;
  }
  .startupinsighttop-heading,
  .startupinsightbottom-heading {
    font-size: 4em;
  }
  .startupinsighttop-heading {
    position: relative;
    top: -12px;
    margin-top: -250px;
  }
  .startupinsightbottom-heading {
    position: relative;
    top: -200px;
  }
  .startupinsightbox1,
  .startupinsightbox2,
  .startupinsightbox3,
  .startupinsightbox4,
  .startupinsightbox5,
  .startupinsightbox6,
  .startupinsightbox7,
  .startupinsightbox8,
  .startupinsightbox9 {
    position: relative;
    top: -400px;
  }
  .startupinsight_footer {
    position: relative;
    top: -400px;
  }
  .startupinsightbottom{
    margin-top:400px;
  }
}
@media screen and (max-width: 420px) {
  .startupinsighttop-heading,
  .startupinsightbottom-heading {
    font-size: 3em;
  }
  .startupinsighttop-heading {
    top: 80px;
  }
  .startupinsightbottom-heading {
    top: -150px;
  }
  .startupinsightright-box {
    width: 280px;
  }
  .startupinsightbox1,
  .startupinsightbox2,
  .startupinsightbox3,
  .startupinsightbox4,
  .startupinsightbox5,
  .startupinsightbox6,
  .startupinsightbox7,
  .startupinsightbox8,
  .startupinsightbox9 {
    width: 300px;
    height: 300px;
  }
  .startupinsightbox-symbol > img {
    width: 40px;
    height: 40px;
  }
}
