.settingsnewfriend {
  display: flex;
  cursor: pointer;
}
.settingsnewfriend > .MuiSvgIcon-root {
  color: #abbbc2;
  height: calc(16 / 1024 * 100vh);
  width: calc(16 / 1440 * 100vw);
  margin-left: calc(24 / 1440 * 100vw);
  margin-right: calc(8 / 1440 * 100vw);
  margin-top: calc(30 / 1024 * 100vh);
}
.settingsnewfriend__content > h2 {
  color: #000000;
  font-size: calc(14 / 1440 * 100vw);
  font-weight: 500;
  margin-top: calc(24 / 1024 * 100vh);
}
.settingsnewfriend__content > h3 {
  color: #abbbc2;
  font-size: calc(12 / 1440 * 100vw);
  font-weight: 400;
  margin-bottom: calc(24 / 1024 * 100vh);
}
.settingsnewfriend:hover {
  background: hsla(9, 75%, 66%, 0.26);
}
.settingsnewfriend :hover h2 {
  color: #ea7c69;
}
