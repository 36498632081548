.sidenavigationbarapp {
  display: flex;
}
.sidenavigationbarside {
  flex: calc(187 / 801 * 900 / 1440);
  height: 85vh;
  background-color: #424242;
  display: flex;
  flex-direction: column;
  left: -100%;
}
