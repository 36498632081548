.chatsidebarChat {
  display: flex;
  padding: 20px;
  cursor: pointer;
  border-bottom: 1px solid #f6f6f6;
}
.chatsidebarChat:hover {
  background-color: #ebebeb;
}
.chatsidebarChat__info {
  margin-left: 15px;
}
.chatsidebarChat__info > h2 {
  font-size: 16px;
  margin-bottom: 8px;
}
