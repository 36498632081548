@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap'); 

.bg-img{
  background-image: url('./Images/IITDelhi.jpg');
  background-repeat: no-repeat;
  background-attachment: fixed;
  height: 100vh;
  width: 100%;
}

.nopadding {
  padding: 0 !important;
  margin: 0 !important;
}

.head{
  font-size: 5rem;
  /* font-size: 6vw; */
    position: absolute;
    /* top: 45%; */
    margin-top:300px;
    left: 40%;
    color: #ffff00;
}

.head2{
  font-size: 2.5rem;
  position: absolute;
  font-style: italic;
  /* top: 63%; */
  margin-top:420px;
  /* left: 35%; */
  width: 100%;
  text-align:center;
  color: #fff;
}
.head2-p{
  color: #fff;
  top: 500px;
}
.head3{
  /* font-size: 1.rem; */
  position: absolute;
  /* top: 77%; */
  /* left: 46%; */
  width:100%;
  margin-top: 520px;
  text-align: center;
  color: #fff;
}

.vl {
  border-left: 6px solid green;
  height: 500px;
}
.btn-explore{
  background-color: rgb(255, 255, 0);
    font-size: 20px;
}

.aboutus{
  font-size: 3.0rem;
    color: black;
    border-bottom: 4px solid #241c15;
    margin-left:40px;
    width: max-content
}

.aboutus-h5{
  font-size: 1.7rem;
    line-height: 2.8rem;
    margin-left: 40px;
    margin-right: 40px;
}

.bg-contact{
  background-image: url('./Images/ben-o-bro-wpU4veNGnHg-unsplash.jpg');
  background-repeat: no-repeat;
  /* background-attachment: fixed; */
  height: auto;
  width: 100%;
}

.brasil {
  font-family: arial;
  position: relative;
   color: #ffff00;
  text-align: center;
  font-size: 5.5rem;
  width:100vw;
  /* max-width: 1200px; */
  margin: 0 auto;
  position: absolute;
    /* top: 27%; */
    /* left: 20%; */
    margin-top:170px;
    z-index: 7;
    font-family: 'Montserrat', sans-serif;
}
.brasil::before,
.brasil::after {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.brasil::before {
  left: 2px;
    clip: rect(79px, 1200px, 86px, 0);
  text-shadow: -1px 0 red;
  background: rgba(0, 0, 0, 0);
  animation: brasil-anim-2 1s infinite linear alternate-reverse;
}

@media (max-width:1100px){
  .head{
    font-size: 6vw;
    margin-top:300px;
  }

  /* .bg-img{
    height: 650px;
  } */

  .brasil{
    font-size: 8vw;
    /* top:20%; */
    /* margin-top:100px; */
  }

  .head2{
    font-size: 5vw;
    /* top:20%; */
    margin-top: 400px;
  }

  .head3{
    margin-top:490px;
  }

}

@media (max-width:700px){
  .head{
    font-size: 6vw;
    margin-top:310px;
  }

  .brasil{
    font-size: 8vw;
    /* top:20%; */
    /* margin-top:100px; */
    margin-top: 230px;
  }

  /* .bg-img{
    height: 550px;
  } */

  .head2{
    font-size: 5vw;
    /* top:20%; */
    margin-top:380px;
  }

  .head3{
    margin-top:460px;
  }

  .about{
    margin-left:1rem;
  }

  .aboutus-h5{
    font-size:4vw;
    line-height:2rem;
    margin-left:1rem;
    margin-right:1rem;
  }
}

/*   */

@keyframes brasil-anim-1 {
  0% {
    clip: rect(20px, 1200px, 76px, 0);
  }
  20% {
    clip: rect(19px, 1200px, 16px, 0);
  }
  40% {
    clip: rect(16px, 1200px, 3px, 0);
  }
  60% {
    clip: rect(62px, 1200px, 78px, 0);
  }
  80% {
    clip: rect(25px, 1200px, 13px, 0);
  }
  100% {
    clip: rect(53px, 1200px, 86px, 0);
  }
}

@keyframes brasil-anim-2 {
  0% {
    clip: rect(79px, 1200px, 86px, 0);
  }
  
  20% {
    clip: rect(20px, 1200px, 30px, 0)
  }
  
  40% {
    clip: rect(25px, 1200px, 5px, 0)
  }
  
  60% {
    clip: rect(65px, 1200px, 85px, 0)
  }
  
  80% {
    clip: rect(120px, 1200px, 145px, 0)
  }
  
  100% {
    clip: rect(95px, 1200px, 75px, 0)
  }
}
