@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
.chatchat {
  display: flex;
  flex-direction: column;
  width: calc(1001 / 1440 * 100vw);
  height: calc(1878 / 2638 * 100vh);
}
.chatchat__message {
  width: fit-content;
  max-width: calc(740 / 1440 * 100vw);
  background-color: rgba(49, 154, 150, 0.19);
  border-radius: 10px;
  margin-left: auto;
  padding: calc(8 / 903 * 100vh) calc(23 / 1440 * 100vw) calc(8 / 903 * 100vh)
    calc(37 / 1440 * 100vw);
  margin-bottom: calc(20 / 903 * 100vh);
}

.chatchat__receiver {
  width: fit-content;
  max-width: calc(740 / 1440 * 100vw);
  background-color: rgba(195, 184, 167, 0.52);
  border-radius: 10px;
  padding: calc(8 / 903 * 100vh) calc(23 / 1440 * 100vw) calc(8 / 903 * 100vh)
    calc(37 / 1440 * 100vw);
  margin-bottom: calc(20 / 903 * 100vh);
}

.chatchat__body {
  background-color: #e5e5e5;
  background-position: center;
  padding: 30px;
  overflow: scroll;
}
.chatchat__footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.chatchat__footer > form {
  display: flex;
  flex: 1;
  background-color: #e5e5e5;
  margin-left: calc(23 / 1440 * 100vw);
  margin-bottom: calc(17 / 1440 * 100vh);
  margin-top: calc(-6 / 903 * 100vh);
  height: calc(67 / 903 * 100vh);
}
::placeholder {
  color: #000000;
  font-weight: 400;
  font-size: 16px;
}
.chatchatinput__container {
  display: flex;
  flex: 1;
  background: rgba(49, 154, 150, 0.19);
  border-radius: 10px;
  padding: 10px;
  align-items: center;
}
.chatchatinput__container > input {
  background-color: rgba(49, 154, 150, 0.19);
  flex: 1;
}

.chatchat__footer > button {
  background: #319a96;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  color: white;
  font-family: "Montserrat";
  font-weight: 400;
  font-size: 24px;
  width: calc(200 / 1440 * 100vw);
  height: calc(59 / 903 * 100vh);
  margin-bottom: calc(25 / 903 * 100vh);
  margin-right: calc(10 / 1440 * 100vw);
  cursor: pointer;
}
.chatchat__footer > .MuiSvgIcon-root {
  height: calc(40 / 903 * 100vh);
  width: calc(40 / 1440 * 100vw);
  color: #000000;
  margin-left: calc(37 / 1440 * 100vw);
  margin-bottom: calc(30 / 903 * 100vh);
  margin-right: calc(46 / 1440 * 100vw);
  cursor: pointer;
}
.chatchatclass_one {
  float: right;
  height: calc(77 / 903 * 100vh);
  width: calc(77 / 1440 * 100vw);
  margin-left: calc(24 / 1440 * 100vw);
}
.chatchatclass_two {
  float: left;
  height: calc(77 / 903 * 100vh);
  width: calc(77 / 1440 * 100vw);
  margin-right: calc(24 / 1440 * 100vw);
}
