@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");

.preincubplan_header {
  height: calc(471 / 2638 * 100vh);
  width: 100vw;
  background-color: #000000;
  display: flex;
}

.preincubplanApp {
  text-align: center;
  box-sizing: border-box;
  background-image: url("https://image.freepik.com/free-photo/white-brick-wall-textures-background_1203-6587.jpg?w=996");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.preincubplanmain-heading {
  margin: 31px 0 0px 0;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 64px;
  line-height: 78px;
}

.preincubplanboxes {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  justify-content: center;
  text-align: left;
}

.preincubplanbox1,
.preincubplanbox2 {
  width: 612px;
  height: 330px;
  background: linear-gradient(180deg, #35454a 0%, #263238 100%);
  border: 1px solid rgba(0, 0, 0, 1);
  border-radius: 15px;
  padding: 30px;
  display: flex;
  flex-wrap: nowrap;
  margin: 32px 80px 30px 80px;
}

.preincubplanbox1 {
  margin-bottom: -2px;
}

.preincubplanbox1 > .preincubplanbox-text {
  padding: 30px 25px 0 10px;
}

.preincubplanbox2 > .preincubplanbox-text {
  padding: 30px 5px 0 20px;
}

.preincubplanbox-text-heading {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 30px;
  color: #fecb2f;
  padding-bottom: 25px;
}

.preincubplanbox2 .preincubplanbox-text-heading {
  padding-bottom: 20px;
}

.preincubplanbox-text-content {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;

  color: #ffffff;

  border: 1px solid #000000;
  backdrop-filter: blur(4px);
}

.preincubplanbox1-image {
  margin-top: 13px;
  margin-right: 30px;
}

.preincubplanbox1-image > img {
  width: 172px;
}

.preincubplanbox2-image {
  margin-top: 0px;
  margin-right: -10px;
}

.preincubplanbox2-image > img {
  width: 154px;
  height: 300px;
}

.preincubplaninviting-our-alumni {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 39px;
  text-align: center;

  padding: 30px 270px 30px 270px;
}

.preincubplanline-under-quotes {
  margin: -70px 0 -70px 0;
}

.preincubplanthe-line {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
}

.preincubplanopening-quotes {
  position: relative;
  top: 60px;
  right: 230px;
}

.preincubplanclosing-quotes {
  position: relative;
  bottom: 60px;
  left: 230px;
  transform: rotate(180deg);
}


.preincubplan_footer {
  height: calc(289 / 2638 * 100vh);
  width: 100vw;
  background-color: #000000;
  display: flex;
}