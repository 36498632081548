.teamcontent {
  background-color: rgb(120, 120, 120);
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  padding-bottom: 50px;
}
.teamcontentcoordinator {
  background-color: white;
  border-radius: 20px;
  display: flex;
  align-items: center;
  padding: 30px;
}
.teamcontentcoordinator h1 {
  text-align: center;
  padding: 10px;
  border-bottom: 3px solid gray;
}
.teamcontentcoordinator_desc_position {
  color: rgb(49, 154, 150);
  font-weight: bold;
  text-align: center;
  margin: 10px;
}
.teamcontentcoordinator_desc_profile {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.teamcontenttechnical_info {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
  align-items: center;
  margin: 20px;
}
.teamcontenttechnical {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.teamcontentdesign {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.teamcontenttechnical_heading h2 {
  border: 4px solid yellow;
  border-radius: 5px;
  padding: 10px 25px;
  margin-top: 30px;
  align-items: center;
  text-align: center;
  width: 200px;
  font-size: 28px;
}
.teamcontenttechnical_infocard {
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 15px;
  padding: 30px;
  margin: 20px 40px;
  align-items: center;
  width: 200px;
  border: 2px solid black;
}
.teamcontenttechnical_infocard_content h3 {
  align-items: center;
  text-align: center;
  border-bottom: 2px solid gray;
}
.teamcontentcoordinator_desc_profile img {
  padding: 5px 20px;
}

@media screen and (max-width:376px) {
  .teamcontentcoordinator {
    padding: 0px;
  }
}
