@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");

.iitdangelnetwork_header {
  height: calc(471 / 2638 * 100vh);
  width: 100vw;
  background-color: #000000;
  display: flex;
}

.iitdangelnetworkApp {
  text-align: center;
  box-sizing: border-box;
  background-image: url("https://image.freepik.com/free-photo/white-brick-wall-textures-background_1203-6587.jpg?w=996");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-attachment: fixed;
  overflow-y: scroll;
}

.iitdangelnetworkmain-heading {
  margin: 31px 0 0px 0;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 64px;
  line-height: 78px;
}

.iitdangelnetworkboxes {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  justify-content: center;
  text-align: left;
}

.iitdangelnetworkbox1,
.iitdangelnetworkbox2 {
  width: 612px;
  height: 350px;
  background: linear-gradient(180deg, #35454a 0%, #263238 100%);
  border: 1px solid rgba(0, 0, 0, 1);
  border-radius: 15px;
  padding: 30px;
  display: flex;
  flex-wrap: nowrap;
  margin: 32px 80px 30px 80px;
}

.iitdangelnetworkbox1 {
  margin-bottom: -2px;
}

.iitdangelnetworkbox1 > .iitdangelnetworkbox-text {
  padding: 30px 25px 0 10px;
}

.iitdangelnetworkbox2 > .iitdangelnetworkbox-text {
  padding: 30px 5px 0 20px;
}

.iitdangelnetworkbox-text-heading {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 30px;
  color: #fecb2f;
  padding-bottom: 25px;
}

.iitdangelnetworkbox2 .iitdangelnetworkbox-text-heading {
  padding-bottom: 20px;
}

.iitdangelnetworkbox-text-content {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;

  color: #ffffff;

  border: 1px solid #000000;
  backdrop-filter: blur(4px);
}

.iitdangelnetworkbox1-image {
  margin-top: 13px;
  margin-right: 30px;
}

.iitdangelnetworkbox1-image > img {
  width: 172px;
}

.iitdangelnetworkbox2-image {
  margin-top: 0px;
  margin-right: -10px;
}

.iitdangelnetworkbox2-image > img {
  width: 154px;
  height: 300px;
}

.iitdangelnetworkinviting-our-alumni {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 39px;
  text-align: center;

  padding: 30px 270px 30px 270px;
}

.iitdangelnetworkline-under-quotes {
  margin: -70px 0 -70px 0;
}

.iitdangelnetworkthe-line {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
}

.iitdangelnetworkopening-quotes {
  position: relative;
  top: 60px;
  right: 230px;
}

.iitdangelnetworkclosing-quotes {
  position: relative;
  bottom: 60px;
  left: 230px;
  transform: rotate(180deg);
}

.iitdangelnetwork_footer {
  height: calc(289 / 2638 * 100vh);
  width: 100vw;
  background-color: #000000;
  display: flex;
}
@media only screen and (max-width: 780px){
  .iitdangelnetworkbox1, .iitdangelnetworkbox2{
    height: 500px;
  }
  .iitdangelnetworkbox1-image{
    margin-top: 93px;
  }
  .iitdangelnetworkbox2-image{
    margin-top: 93px;
  }
}
@media only screen and (max-width: 720px){
  .iitdangelnetworkinviting-our-alumni{
    padding: 30px 30px 30px 30px;
  }
}
@media only screen and (max-width: 608px){
  .iitdangelnetworkopening-quotes{
    right: 200px;
  }
  .iitdangelnetworkclosing-quotes{
    left: 180px;
  }
}
@media only screen and (max-width: 510px){
  .iitdangelnetworkthe-line{
    font-size: 1em;
  }
  .iitdangelnetworkopening-quotes{
    right: 130px;
    width: 50px;
    height: 50px;
    top: 35px;
  }
  .iitdangelnetworkclosing-quotes{
    left: 120px;
    width: 50px;
    height: 50px;
    top: -35px;
  }
  .MuiSvgIcon-root{
    margin-top: 50px;
    margin-bottom: 20px;
  }
}
@media only screen and (max-width: 470px){
  .iitdangelnetworkbox1, .iitdangelnetworkbox2{
    width:280px ;
    height: auto;
    display: flex;
    flex-direction: column;
  }
  .iitdangelnetworkbox-text-heading{
    font-size: 1.3em;
  }
  .iitdangelnetworkbox-text-content{
    font-size: 1.1em;
  }
  .iitdangelnetworkinviting-our-alumni{
    font-size: 1.2em;
  }
  .iitdangelnetworkmain-heading{
    font-size: 2em;
  }
}