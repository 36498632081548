.registerpagebody {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(rgb(64, 64, 64), rgb(190, 190, 190));
  height: calc(1878 / 2638 * 100vh);
  position: relative;
}
.registerpagebody-tag {
  display: flex;
  height: calc(601 / 714 * 100%);
  flex-direction: column;
  align-items: center;
  justify-self: space-between;
  margin-top: 40px;
  margin-right: 20px;
  margin-left: 20px;
  margin-bottom: 40px;
  background-color: black;
  border-radius: 20px;
  width: 20%;
  padding: 20px;
  background-image: linear-gradient(black 87%, rgb(254, 203, 47) 13%);
}
.registerpagebody-tag-top {
  font-size: 20px;
  text-align: center;
  color: rgb(254, 203, 47);
  background-color: black;
}
.registerpagebody-tag-content {
  color: azure;
  text-align: center;
  padding: 20px;
  height: 340px;

  /* Height was given as 280px initially */
}
.registerpagebody-tag-content{
  overflow: hidden;
}
.registerpagebody-tag-bottom {
  text-align: center;
  color: azure;
  width: 100%;
  font-size: 18px;
}
.registerpagebody-img {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 150px;
}
.registerpagebodyreg {
  height: 440px;
}
